import React from 'react'
import styled from 'styled-components'

import {
  Link,
  List,
  ListItem,
  NotificationBar,
  NotificationBarContainer,
  Paragraph
} from '@te-digi/styleguide'

import { Code } from '../../components/Code'
import { Playground } from '../../components/Playground'
import { Section } from '../../components/Section'
import { Content } from '../../components/Content'
import { Link as GatsbyLink } from '../../components/Link'

import { NotificationBarExample } from '../../examples/react/NotificationBarExample'
import { StatusMessage } from '../../components/StatusMessage'

const NotificationBarWrapper = styled.div`
  & > * {
    position: relative;
    z-index: 0;
  }
`

const Page = () => (
  <Content
    heading="NotificationBar"
    subHeading="Vanhentunut"
    components={[
      { component: NotificationBar },
      { component: NotificationBarContainer }
    ]}
    status={[
      { type: 'deprecated', version: '15.0.0' },
      { type: 'accessible', version: '15.1.0' }
    ]}
  >
    <Section>
      <StatusMessage
        status={{ type: 'breaking_changes', version: '15.0.0' }}
        messageInfo={
          <>
            <Paragraph>
              .notification-bar -tyylit poistettu, korvaaja{' '}
              <Code>
                <GatsbyLink page="SiteNotifications" />
              </Code>
              . Huom. myös HTML-rakenne muuttunut.
            </Paragraph>
            <Paragraph>
              React-komponentti säilyy ennallaan, mutta poistetaan tulevassa
              versiossa.
            </Paragraph>
            <Paragraph noMargin>React-komponentin migraatio-ohje:</Paragraph>
            <List variant="unordered">
              <ListItem>
                <Code>&lt;NotificationBarContainer&gt;</Code>-komponentti
                muutetaan <Code>&lt;SiteNotifications&gt;</Code>-komponentiksi
              </ListItem>
              <ListItem>
                <Code>&lt;NotificationBar&gt;</Code>-komponentti muutetaan{' '}
                <Code>&lt;SiteNotification&gt;</Code>-komponentiksi
              </ListItem>
              <ListItem>
                <Code>&lt;NotificationBar variant=&quot;error&quot;&gt;</Code>
                -komponentti siirretään <Code>&lt;SiteNotifications&gt;</Code>
                -komponentin <Code>alert</Code>-proppiin{' '}
                <Code>&lt;SiteNotification&gt;</Code>
                -komponenttina (ilman <Code>variant</Code>-proppia), katso{' '}
                <Code>
                  <Link href="/palautteet/sitenotifications/#varoitus-seka-muita-tiedotteita">
                    SiteNotifications esimerkki
                  </Link>
                </Code>
                .
              </ListItem>
            </List>
          </>
        }
      />
    </Section>

    <Section title="Käyttö">
      <Paragraph noMargin>
        <Code>NotificationBar</Code>-komponentit lisätään{' '}
        <Code>NotificationBarContainer</Code>in sisään. Shadow-efekti on
        containerissa. Sen sisään voi sijoittaa useita{' '}
        <Code>NotificationBar</Code>eja. Jos elementtejä on monta, tulee
        huomioida, että ne saattavat peittää esim. navigaatiota.
      </Paragraph>
    </Section>

    <Section title="Yläpalkki">
      <Playground
        format="react"
        enableOverflow
        example={NotificationBarExample}
        WrapperComponent={NotificationBarWrapper}
      />
    </Section>
    <Section title="Saavutettavuus">
      <Paragraph noMargin>Tarkistuslista:</Paragraph>
      <List variant="unordered">
        <ListItem>
          Varmista, että komponentin teksti on kuvaava ja kertoo ytimekkäästi
          viestin. Komponentti ei saa viedä liikaa tilaa näytöltä pitkän viestin
          vuoksi.
        </ListItem>
        <ListItem>
          Jotta tärkeät ilmoitukset luetaan ruudunlukijalla heti sivulle
          saavuttaessa, <Code>NotificationBarContainer</Code> sijoitetaan ennen
          muuta sisältöä sivun alkuun,{' '}
          <GatsbyLink page="SkipLink">SkipLinkin</GatsbyLink> perään.
        </ListItem>
      </List>
    </Section>
  </Content>
)

export default Page
