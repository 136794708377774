import React from 'react'

import {
  Link,
  NotificationBar,
  NotificationBarContainer
} from '@te-digi/styleguide'

import { LinkMock } from '../../components/LinkMock'

const NotificationBarExample = () => (
  <NotificationBarContainer>
    <NotificationBar>
      Suunniteltu huoltokatko ma x.x.2023 klo 15-23. Pahoittelemme katkosta
      aiheutuvaa haittaa.
    </NotificationBar>
    <NotificationBar variant="error">
      Asiantuntijan työmarkkinatorilla on tietoliikennehäiriö. Tilannetta
      korjataan. Pahoittelemme häiriöstä aiheutuvaa haittaa.{' '}
      <Link as={LinkMock}>Lue lisää häiriötilanteesta</Link>
    </NotificationBar>
  </NotificationBarContainer>
)

export { NotificationBarExample }
