import React, { Fragment, ReactElement } from 'react'
import { Heading, Message, MessageHeader, Text } from '@te-digi/styleguide'

import { Code } from './Code'
import { Link } from './Link'
import { ComponentStatusInfo } from './StatusTypes'

interface StatusMessageProps {
  messageInfo?: ReactElement
  replacements?: string[]
  status: ComponentStatusInfo
}

const StatusMessage = ({
  messageInfo,
  replacements = [],
  status
}: StatusMessageProps) => {
  let messageHeader = ''
  let messageBody: ReactElement | undefined

  switch (status.type) {
    case 'breaking_changes':
      messageHeader = 'Rikkova muutos Style Guide v.' + status.version
      messageBody = messageInfo
      break
    case 'deprecated':
      messageHeader = 'Komponentti poistettu käytöstä'
      messageBody = replacements ? (
        <Text>
          Korvaavat komponentit:{' '}
          {replacements.map((replacement, index) => (
            <Fragment key={index}>
              {index + 1 === replacements.length && index !== 0 ? 'ja ' : ''}
              <Code>
                <Link page={replacement} />
              </Code>
              {index + 1 === replacements.length ? '' : ' '}
            </Fragment>
          ))}
        </Text>
      ) : (
        <Text>''</Text>
      )
      break
    default:
      break
  }

  return messageHeader ? (
    <Message
      attentionColor={status.type === 'deprecated' ? 'danger' : 'secondary'}
    >
      <MessageHeader>
        <Heading
          level={2}
          size={4}
        >
          {messageHeader}
        </Heading>
      </MessageHeader>
      {messageBody}
    </Message>
  ) : null
}

export { StatusMessage }
